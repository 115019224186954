@import '../../styles';

#headerNotLoggedIn,
#header {
  position: fixed;
  top: 0px;
  width: 100%;
  margin-top: 30px;
  z-index: 999;
  // margin-left: 30px;
}

#headerNotLoggedIn {
  width: auto;
  display: inline-block;
}

.language {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
  margin-right: 30px;
}

.progress {
  position: absolute;
  top: 13px;
  right: 0px;
  text-align: right;
  margin-right: 80px;
  font-size: 10px;
  line-height: normal;
  @include mediaMobile() {
    margin-right: 60px;
  }
}
.logo {
  position: relative;
  display: inline-block;
  height:36px;
  width:36px;
}

.logoMobileWrapper {
  position: relative;
  margin-left: 50px;
  display: inline-block;
  @include mediaMobile() {
    margin-left: 20px;
  }
}

.logoLockup {
  position: absolute;
  display: flex;
  margin-left: 50px;
  @include mediaMobile() {
    margin-left: 20px;
  }
}
.chapter {
  position: absolute;
  font-size: 12px;
  left: 80px;
  // top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: max-content;
  @include mediaMobile() {
    left: 65px;
  }
  @include respond-to($xs-phone) {
    width: fit-content;
  }
  &.light {
    color: $white;
  }
  &.dark {
    color: $black;
  }
}

.vl {
  border-left: 1px solid rgba(255, 255, 255, 0.8);
  margin-left: 20px;
  @include mediaMobile() {
    margin-left: 13px;
  }
  &.light {
    border-left: 1px solid rgba(255, 255, 255, 0.8);
  }
  &.dark {
    border-left: 1px solid rgba(0, 0, 0, 0.8);
  }
}
