@import '../../../../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1.4);
  }
}

.pressAndHoldContainerHolder {
  position: absolute;
  left: var(--poition-width);
  top: var(--poition-height);
}

.pressAndHoldContainer {
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0);
  // background: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding-top: 1px; // fix Safari issue where top was appearing cut off CRWT-280

  &:hover {
    cursor: pointer;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.directionalIcon {
  position: absolute;
  left: 4%;
  transform: rotate(-5deg);
  > svg {
    path {
      stroke: var(--themecurr-color);
    }
  }
}

.outterCircle {
  height: 45px;
  width: 45px;
  background: var(--themecurr-color);
  opacity: 0.3;
  border-radius: 50%;
  pointer-events: none;
  animation: opacityAnim 1.5s infinite alternate;
}

.middleCircle {
  height: 60%;
  width: 60%;
  background-color: var(--themecurr-color);
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  pointer-events: none;
  opacity: 0.3;
  animation: opacityAnim 1.5s infinite alternate;
  animation-delay: 0.5s;
}

.innerCircle {
  height: 40%;
  width: 40%;
  background-color: var(--themecurr-color);
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  pointer-events: none;
}

.drag {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  // background: red;
}

.dragNoEvent {
  pointer-events: none !important;
}
