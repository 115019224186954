@import '../../styles/utils.scss';


.wrapper {
  margin-top: -40px;
  @include mediaMobile() {
    margin-top: 0px;
  }
  &.japan {
    margin-top: 10px !important;
  }
}

.image {
  width: 37px;
  height: 37px;
  @include mediaMobile() {
    width: 27px;
    height: 27px;
  }
}