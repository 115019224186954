@import "./utils";
@import "./reset";
@import url("https://use.typekit.net/cef6krs.css");

// @font-face {
//   font-family: "apercu-mono-regular";
//   src: url("/fonts/apercu-mono-regular.woff2") format("woff2"),
//     url("/fonts/apercu-mono-regular.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

$mobile-grid-column-size: #{(73/414) * 100%};

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: "Neuzeit Grotesk", "Helvetica Neue", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: $regular;
  font-style: normal;
  background-size: cover;
  font-size: 12px;
  // scroll-behavior: smooth;

  @include respond-to($desktop) {
    font-size: 24px;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
}

body {
  overflow: hidden;
}

#___gatsby {
  overflow: hidden;
}

.no-focus-outline:focus {
  outline: none;
}

div:focus {
  outline: none;
}
a,
div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  .grid {
    opacity: 0;
  }
  #smooth-scroll {
    div:first-of-type,
    section:first-of-type,
    footer {
      opacity: 0;
    }
  }
}

body.loaded {
  .grid {
    opacity: 1;
  }
  #smooth-scroll {
    div:first-of-type,
    section:first-of-type,
    footer {
      opacity: 1;
    }
  }
}

// Defaults for special design elements

.mobile {
  display: block;
  @include respond-to($desktop) {
    display: none;
  }
}

.desktop {
  display: none;

  @include respond-to($desktop) {
    display: block;
  }
}

section {
  position: relative;
  width: 100vw;
  padding-top: 5vh;
  padding-bottom: 8vh;
  display: block;

  &:first-of-type {
    padding-top: 19vh;
  }

  @include respond-to($desktop) {
    margin: auto;
    padding-bottom: 5vh;

    &:first-of-type {
      padding-top: 28vh;
    }
  }
}

#__RAIMG {
  position: absolute;
}

.grid {
  display: grid;
  display: -ms-grid;
  margin: 0;
  grid-gap: 5vw;
  grid-row-gap: 0;
  max-width: $max_width;
  grid-template-columns: repeat(4, $mobile-grid-column-size);
  -ms-grid-columns: $mobile-grid-column-size $mobile-grid-column-size
    $mobile-grid-column-size $mobile-grid-column-size;
  justify-content: right;

  //This is done to justify the context to the right in IE11 for mobile.
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    transform: translateX(100vw) translateX(-$mobile-grid-column-size)
      translateX(-$mobile-grid-column-size)
      translateX(-$mobile-grid-column-size)
      translateX(-$mobile-grid-column-size);
  }

  &.centered-section {
    justify-content: center;
  }

  &__mobile-padding-right {
    @include respond-to($mobile) {
      padding-right: 5vw;
    }
  }

  @include respond-to($desktop) {
    margin: auto;
    grid-gap: 4vw;
    grid-row-gap: 0;
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    padding-left: 5vw;
    padding-right: 5vw;
    justify-content: unset;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      transform: translateX(0);
    }
  }
}

.fullScreen {
  max-width: 100%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    transform: translateX(0);
  }
}

/* Underline effect */

%underline {
  background-image: linear-gradient(
    to left,
    currentColor 100%,
    hsla(0, 0%, 100%, 0)
  );
  background-size: 100% 3px;
  background-position: 0 100%;
  line-height: 1.1;
  background-repeat: no-repeat;
  transform-origin: left bottom;
  transition: background-size 500ms cubic-bezier(0.23, 1, 0.32, 1);
  &:hover {
    background-size: 0 3px;
  }
}

.underline-reverse {
  @extend %underline;
  background-size: 0 3px;
  &:hover {
    background-size: 100% 3px;
  }
}

.underline-reverse-thin {
  @extend %underline;
  background-size: 0 3px;
  &:hover {
    background-size: 100% 1px;
  }
}

.underline {
  @extend %underline;
  background-size: 100% 3px;
  &:hover {
    background-size: 0 3px;
  }
}

.underline-thin {
  @extend %underline;
  background-size: 100% 1px;
  &:hover {
    background-size: 0 1px;
  }
}

.blobContainer {
  position: fixed;
  top: 0%;
  left: 0%;
  cursor: pointer;
  pointer-events: none;
}

::selection {
  @include selection-effect($white);
}

.ctaButton {
  display: inline-block;
  cursor: pointer;
  background: white;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  width: 180px;
  height: 50px;
  font-size: 12px;
  border-radius: 100px;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 5%;
  z-index: 10;
  text-align: center;
  line-height: 50px;
  padding: 0;
  &.outroCta {
    @media all and (min-width: 481px) {
      position: initial;
      transform: initial;
      margin-bottom: 1em;
    }
    @media all and (min-width: 769px) {
      margin-bottom: 2em;
    }
  }

  &.centerBottom {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 10px;
  }
  &.light {
    background: white;;
    color: black;
  }
  &.dark {
    background: black;
    color: white;
  }
}
.chevronRight {
  height: 10px;
    width: 10px;
  // background: url("../img/chevron-right.svg") center / cover;
  // height: 10px;
  // width: 10px;
  // position: absolute;
  // right: 10%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}
