@import '../../styles';
.audioControl {
	position: fixed;
	bottom: 20px;
	right: 20px;
}

.welcome {
	background-image: url(../../img/global/menu_bg.png);
	width: 100%;
	height: 100%;
	position: absolute;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.welcomeContent {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	margin: 0 50px;
	width: calc(100% - 100px);
	max-width: 980px;

	@include mediaMobile() {
		height: auto;
		overflow-y: auto;
		margin: 0 0 0 20px;
		padding-right: 20px;
		width: calc(100% - 40px);
		justify-content: flex-start;
		padding-top: 120px;
		padding-bottom: 100px;
	}
}

.welcomeContent h1 {
	font-size: 64px;
	line-height: 1;
	font-weight: 300;
	margin-bottom: 50px;

	@include mediaTablet() {
		font-size: 48px;
	}

	@include mediaMobile() {
		font-size: 30px;
		margin-bottom: 30px;
	}

	@include respond-to($xs-phone) {
		font-size: 29px;
	}
}

.welcomeContent .subTitle {
	font-size: 32px;
	line-height: 1;
	font-weight: 300;
	margin-bottom: 50px;

	@include mediaTablet() {
		font-size: 20px;
	}

	@include mediaMobile() {
		font-size: 16px;
		margin-bottom: 15px;
	}
}

.welcomeContent ul {
	display: flex;
	justify-content: space-between;

	@include mediaMobile() {
		flex-direction: column;
	}
}

.welcomeContent ul li {
	font-size: 16px;
	line-height: 1.3;
	font-weight: 300;
	max-width: 215px;
	display: flex;
	flex-direction: column;
	padding-right: 15px;

	@include mediaTablet() {
		max-width: 100%;
	}

	@include mediaMobile() {
		border-top: 1px solid rgba(255, 255, 255, .2);
		padding: 25px 0;
		flex-direction: row;
	}
}

.welcomeContent ul li:last-child {
	@include mediaTablet() {
		padding-right: 0;
	}

	@include mediaMobile() {
		border-bottom: 1px solid rgba(255, 255, 255, .2);
	}
}

.welcomeContent ul li .icon {
	height: 62px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	@include mediaMobile() {
		min-width: 65px;
		margin: 0 30px;
	}
}

.welcomeContent ul li .icon img {
	max-height: 62px;

	@include mediaMobile() {
		max-height: 60px;
	}
}

.tmpNextLink {
	position: absolute;
	left: 2em;
}

.touchNav {
	position: absolute;
	bottom: 30px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.touchNavButton a span {
	display: inline-block;
	width: 180px;
	background: #fff;
	color: #000;
	text-align: center;
	padding: 15px 0;
	font-size: 14px;
	font-weight: bold;
	border-radius: 30px;
	border: 1px solid #fff;
}

.touchNavButton a:hover span,
.touchNavButton a:active span {
	background: transparent;
	color: #fff;
}