.sign-in-wrapper {
	display: flex;
	position: absolute;
	left: 40%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: left;
}

.title {
	font-size: 32px;
	margin-left: 0;
}

.forgot-description {
	color: rgba(255,255,255,0.7);
	font-size: 16px;
	line-height: 20px;
}

.input-wrapper {
	display: flex;
	margin-top: 35px;
	input {
		width: 100%;
		border-color: rgba(255,255,255,0.7);
		&.error {
			border-color: #EF2642;
		}
		&::placeholder {
			color: rgba(255,255,255,0.7);
		}
	}

}

.error-message {
	color: #EF2642;
	font-size: 14px;
	height: 100px;
	transition: opacity 0.25s;
	margin-top: 30px;
	&.visible {
		opacity: 1;
	}
	&.hidden {
		opacity: 0;
	}
}

.submit-login {
	height: 23px;
	width: 23px;
	border: 1px solid white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0%;
}
.inactive {
	opacity: .3;

}
.success-wrapper {
	display: flex;
	align-items: center;
	margin-top: 35px;
	p {
		font-size: 16px;
		margin-left: 10px;
		margin-top: 3px;
	}
}