@import '../../../../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  position: relative;
  width: 100%;
  height: 0;
  right: 0;
  top: 0;
  animation: opacityAnim 0.5s ease-in-out;

  @include mediaMobile() {
    display: none;
  }

  .wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 300px;

    .text {
      width: calc(100% - 50px);
      text-align: right;
      padding-right: 20px;

      h4,
      p {
        font-size: 14px;
        margin: 0;
        line-height: 1.2;
      }
    }

    .albumCover {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }
}
