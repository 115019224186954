@import "../../../styles/utils.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}
.container {
  max-width: 780px;
  padding: 10px 50px;
  overflow: hidden;
  display: flex;
  justify-items: center;
  @include mediaMobile() {
    max-width: none;
    padding: 0 1.5em;
  }
}

.eyebrow {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 1em;
}
.text {
  font-size: 64px;
  line-height: 1;
  font-weight: 300;
  span {
    font-weight: lighter;
    vertical-align: text-top;
  }
  .cutoff {
    vertical-align: sub;
    vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;;
  }

  em {
    font-weight: 700;
    font-style: normal;
  }

  @include mediaTablet() {
    font-size: 48px;
  }

  @include mediaMobile() {
    font-size: 32px;
  }

  @include respond-to($xs-phone) {
    font-size: 29px;
  }
}
