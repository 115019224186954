@import '../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1.4);
  }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.outterCircle {
    width: 72%;
    height: 72%;
    background: #FF1535;
    opacity: 0.3;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    animation: opacityAnim 1.5s infinite alternate;
}
  
.middleCircle {
    width: 47%;
    height: 47%;
    background-color: #FF1535;
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    pointer-events: none;
    opacity: 0.3;
    animation: opacityAnim 1.5s infinite alternate;
    animation-delay: 0.5s;
}

.innerCircle {
    width: 22%;
    height: 22%;
    background-color: #FF1535;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    pointer-events: none;
}