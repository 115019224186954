@import '../../../styles/utils.scss';

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 160px 50px 75px;

  @media (any-hover: none) {
    padding-bottom: 95px;
  }

  @include mediaMobile() {
    padding: 90px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }

  .colorsPickerWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;

    @include mediaTablet() {
      left: 0;
      right: auto;
      top: auto;
      bottom: 10%;
      width: 100%;
      transform: unset;
      z-index: unset;
    }

    @include mediaMobile() {
      bottom: 12%;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
      @include mediaTablet() {
        flex-direction: row;
      }

      li {
        padding: 20px 0;
        position: relative;

        @include mediaTablet() {
          padding: 0 15px;
          position: unset;
        }

        span.textColor {
          position: absolute;
          font-size: 12px;
          width: 100px;
          text-align: right;
          opacity: 0;
          right: 50px;
          top: 50%;
          transform: translateY(-50%);

          @include mediaTablet() {
            text-align: center;
            padding: 10px 0 20px;
            left: 0;
            bottom: 100%;
            width: 100%;
            right: auto;
            top: auto;
            transform: unset;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 0;
          margin: 0;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          overflow: hidden;
          transform: rotate(60deg);
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            width: 30%;
            height: 30%;
            background-color: white;
            border-radius: 100%;
            box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.54);
            opacity: 0;
            transition: 0.3s;
          }

          span.colorDot {
            flex-grow: 1;
            width: 100%;
            height: 100%;
          }
        }

        &:global(.active) {
          span.textColor {
            opacity: 1;
          }

          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@mixin supMain {
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
}

.title {
  font-size: 81px;
  font-weight: 400;
  @include supMain();
  @include mediaTablet() {
    font-size: 48px;
  }
}

.text {
  font-size: 12px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;
  @include supMain();
  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
.left {
  text-align: left !important;
}
.textCTA {
  margin-top: 12px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 20%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
  @include mediaMobile() {
    width: 90%;
    padding: 0 23px;
    position: absolute;
    bottom: 15%;
    margin-top: 0;
  }
}

// leftAlign variant
.leftAlign {
  .container {
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;
  }
  .title,
  .text {
    width: 20%;
    max-width: 282px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 0.75em;
  }
  .text {
    font-size: 18px;
    flex-basis: auto;
    text-align: left;
  }
  .textCTA {
    float: none;
  }

  @include mediaTablet() {
    .title,
    .text {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaMobile() {
    .title {
      font-size: 2.1rem;
      padding-right: 0;
      margin-bottom: 0.1em;
    }
    .text {
      font-size: 1rem;
    }
  }
  @include respond-to($xs-phone) {
    .title {
      font-size: 1.7rem;
    }
    .text {
      font-size: 0.9rem;
    }
  }
}
