@import '../../../../../styles/utils.scss';

.containerSlider {
  position: relative;
  width: 200px;
  padding: 15px 0;
  margin: 0 auto;

  :global(.slick-list) {
    overflow: unset !important;

    :global(.slick-slide) {
      opacity: 0.3;
      cursor: pointer;

      &:global(.slick-active) {
        opacity: 1;
      }
    }
  }
}
