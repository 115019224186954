@font-face {
  font-family: 'apercu mono';
  src: url(./fonts/apercu-mono-regular.woff) format('woff'),
      url(./fonts/apercu-mono-regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('./fonts/NeuzeitGro-Bla.woff2') format('woff2'),
    url('./fonts/NeuzeitGro-Bla.woff') format('woff'),
    url('./fonts/NeuzeitGro-Bla.ttf') format('truetype'),
    url('./fonts/new-NeuzeitGro-Bla.otf') format('otf');
  font-weight: bolder;
}

@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('./fonts/NeuzeitGro-Bol.woff2') format('woff2'),
    url('./fonts/NeuzeitGro-Bol.woff') format('woff'),
    url('./fonts/NeuzeitGro-Bol.ttf') format('truetype'),
    url('./fonts/new-NeuzeitGro-Bol.otf') format('otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('./fonts/NeuzeitGro-Lig.woff2') format('woff2'),
    url('./fonts/NeuzeitGro-Lig.woff') format('woff'),
    url('./fonts/NeuzeitGro-Lig.ttf') format('truetype'),
    url('./fonts/new-NeuzeitGro-Lig.otf') format('otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('./fonts/NeuzeitGro-Reg.woff2') format('woff2'),
    url('./fonts/NeuzeitGro-Reg.woff') format('woff'),
    url('./fonts/NeuzeitGro-Reg.ttf') format('truetype'),
    url('./fonts/new-NeuzeitGro-Reg.otf') format('otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Neuzeit Grotesk Cond';
  src: url('./fonts/NeuzeitGroCon-Bla.woff2') format('woff2'),
    url('./fonts/NeuzeitGroCon-Bla.woff') format('woff'),
    url('./fonts/NeuzeitGroCon-Bla.ttf') format('truetype'),
    url('./fonts/new-NeuzeitGroCon-Bla.otf') format('otf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  content: "next";
}

.cursor--hidden {
  opacity: 0;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}

html,
body {
  /* cursor: none; */
  /* background-color: #850808; */
  color: #ffffff;
  margin: 0px;
  height: 100%;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}
.hide {
  display: none;
}

.underline {
  text-decorrfdtation: underline;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjI4MiIgaGVpZ2h0PSI0LjUxNiIgdmlld0JveD0iMCAwIDcuMjgyIDQuNTE2Ij4KICA8cGF0aCBpZD0iUGF0aF85IiBkYXRhLW5hbWU9IlBhdGggOSIgZD0iTTAsNS4xNjEsMi43MDYsMi41ODEsMCwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjIyMiAxLjA2KSByb3RhdGUoOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPC9zdmc+Cg==')
    !important;
  background-repeat: no-repeat, repeat !important;
  background-position: right .7em top 50%, 0 0 !important;
  background-size: .65em auto, 100% !important;
  border-radius: 0px;
  padding: 0em 0em 0em .35em;
}