@import '../../../styles/utils.scss';

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 160px 50px 75px;

  @media (any-hover: none) {
    padding-bottom: 95px;
  }

  @include mediaMobile() {
    padding: 90px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }
}

@mixin supMain {
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
}

.title {
  font-size: 81px;
  font-weight: 400;
  @include supMain();
  @include mediaTablet() {
    font-size: 48px;
  }
  @include mediaTabletPortrait() {
    font-size: 48px;
  }
}

.text {
  font-size: 12px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;
  @include supMain();
  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
.left {
  text-align: left !important;
}
.textCTA {
  margin-top: 12px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 20%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
  @include mediaMobile() {
    width: 90%;
    padding: 0 23px;
    position: absolute;
    bottom: 15%;
    margin-top: 0;
  }
}

// leftAlign variant
.leftAlign {
  .container {
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;
  }
  .title,
  .text {
    width: 20%;
    max-width: 282px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 0.75em;
  }
  .text {
    font-size: 18px;
    flex-basis: auto;
    text-align: left;
  }
  .textCTA {
    float: none;
  }

  @include mediaTablet() {
    .title,
    .text {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaTabletPortrait() {
    .title,
    .text {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaMobile() {
    .title {
      font-size: 2.1rem;
      padding-right: 0;
      margin-bottom: 0.1em;
    }
    .text {
      font-size: 1rem;
    }
  }
  @include respond-to($xs-phone) {
    .title {
      font-size: 1.7rem;
    }
    .text {
      font-size: 0.9rem;
    }
  }
}
