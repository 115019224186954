@import "../../../styles/utils.scss";

.container {
  display: flex;
  align-items: flex-start;
  justify-content: normal;
  flex-direction: column;
  height: 100%;
  padding: 160px 50px 75px;

  @media (any-hover: none) {
    padding-bottom: 95px;
  }

  @include mediaMobile() {
    padding: 90px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }
}

@mixin supMain {
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
}

.title, .text {
  width: 20%;
  max-width: 282px;

  @include mediaTablet() {
    width: 100%;
    max-width: none;
  }
}

.title {
  position: relative;
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 0.75em;

  @include supMain();
  @include mediaTablet() {
    padding-right: 2em;
    margin-bottom: 0.25em;
  }

  @include mediaMobile() {
    font-size: 2.1rem;
    padding-right: 0;
    margin-bottom: 0.1em;
  }

  @include respond-to($xs-phone) {
    font-size: 1.7rem;
  }
}

.text {
  position: relative;
  line-height: 1.333;
  font-size: 18px;
  flex-basis: auto;
  text-align: left;

  @include supMain();
  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 1rem;
  }

  @include respond-to($xs-phone) {
    font-size: 0.9rem;
  }
}
.left {
  text-align: left !important;
}

.maskSliderContainer {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mediaTablet() {
    align-items: flex-end;
  }

  @include mediaMobile() {
    height: calc(100vh - 150px);
  }

  .draggable {
    position: absolute;
    z-index: 30;
    height: calc(100vh - 150px);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .hotspot {
      z-index: 10;
      position: absolute;
      margin-top: 200px;
      width: 57px;
      height: 57px;
      cursor: grab;
      display: block;

      @include mediaTablet() {
        bottom: 150px;
      }
    }

    .hotspot.dragging {
      cursor: grabbing;
    }

    .hotspotArrows {
      z-index: 5;
      position: absolute;
      margin-top: 200px;

      @include mediaTablet() {
        bottom: 163px;
      }
    }

    .line {
      height: 100%;
      width: auto;
    }
  }

  .maskSlider {
    position: absolute;
    z-index: 20;
    width: auto;
    max-width: 100%;

    @include mediaTablet() {
      margin-bottom: 70px;
    }

    .overlay {
      position: absolute;
      z-index: 5;

      @include mediaTablet() {
        img {
          max-width: 100vw;
        }
      }
    }

    .over {
      position: absolute;
      overflow: hidden;
      z-index: 10;
      
      @include mediaTablet() {
        img {
          max-width: 100vw;
        }
      }
    }
    .under {
      width: auto;

      @include mediaTablet() {
        img {
          max-width: 100vw;
        }
      }
    }
  }
}
