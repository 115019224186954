.carouselWrapper {
  :global {
    .carousel {
      width: 100%;
      margin-top: 58px;
      overflow: hidden !important;
    }

    /* the slides */
    .slick-slide {
      width: 330px !important;
    }
    .slick-slide > div {
      margin: 0 15px !important;
    }
    /* the parent */
    .slick-list {
      margin: 0 -15px !important;
      overflow: hidden !important;
    }

    .slick-track {
      margin-left: 50px !important;
      overflow: hidden !important;
    }

    .slick-prev,
    .slick-next {
      right: 50px !important;
      background: url('../images/arrow.svg') center / cover !important;
      width: 22px !important;
      height: 13.4px !important;
      transition: opacity 0.25s;
      z-index: 20;
      top: 36% !important;
      &::before {
        opacity: 0 !important;
      }
      &.slick-disabled {
        opacity: 0;
      }
    }
    .dark {
      .slick-prev {
        background: url('../images/arrow-dark.svg') center / cover !important;
        transform: rotate(180deg) !important;
      }
      .slick-next {
        background: url('../images/arrow-dark.svg') center / cover !important;
      }
      .slick-prev {
        background: url('../images/arrow-dark.svg') center / cover !important;
      }
    }

    .slick-prev {
      left: 50px !important;
      right: 0px !important;
      transform: rotate(180deg) !important;
    }
  }
}
