@import '../../../../../styles/utils.scss';

.interactiveStep {
  z-index: 20;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  :global(.iconWrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 80px;

    svg {
      margin-top: 5px;
      width: auto;
      fill: var(--steps-main-color);
    }

    @include mediaMobile() {
      svg {
        transform: scale(1.2);
      }
    }
  }

  :global(.text) {
    font-size: 12px;
    line-height: 18px;
    max-width: 140px;
    margin-top: 18px;
    color: var(--steps-main-color);

    @include mediaTablet() {
      font-size: 11px;
      margin-top: 10px;
    }

    @include mediaMobile() {
      font-size: 14px;
      margin-top: 18px;
    }
  }
}
