@import '../../../../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1.4);
  }
}

.pressAndHoldContainerHolder {
  position: absolute;
  left: var(--poition-width);
  top: var(--poition-height);
}

.pressAndHoldContainer {
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0);
  // background: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding-top: 1px; // fix Safari issue where top was appearing cut off CRWT-280

  &:hover {
    cursor: pointer;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.directionalIcon {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  bottom: 15%;
  > svg {
    width: 90%;
    path {
      stroke: var(--themecurr-color);
    }
  }
}

.outterCircle {
  height: 40px;
  width: 40px;
  background: var(--themecurr-color);
  opacity: 0.3;
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  animation: opacityAnim 1.5s infinite alternate;
}

.middleCircle {
  height: 30px;
  width: 30px;
  background-color: var(--themecurr-color);
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  pointer-events: none;
  opacity: 0.3;
  animation: opacityAnim 1.5s infinite alternate;
  animation-delay: 0.5s;
}

.innerCircle {
  height: 16px;
  width: 16px;
  background-color: var(--themecurr-color);
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  pointer-events: none;
}

.drag {
  position: absolute;
  width: 50px;
  height: 100%;
  // background: blue;
  opacity: 0.3;

  &.rev {
    right: 0;
  }

  &.def {
    left: 0;
  }
}

.dragNoEvent {
  pointer-events: none !important;
}
