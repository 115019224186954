.root {
  position: fixed;
  z-index: 10;
}
.cursor {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  cursor: none;

  &.prev {
    transform: translate3d(-50%, -50%, 0) rotate(180deg);
  }
}
.progressCursor {
  pointer-events: none;
}
