/* Respond Above */
@mixin respond-to($mq) {
  @media all and #{$mq} {
    @content;
  }
}

/* Respond Below */
@mixin mediaBigTablet() {
  @media all and (max-width: 1024px) {
    @content;
  }
}

/* Respond Below */
@mixin mediaTablet() {
  @media all and #{$tabletDown} {
    @content;
  }
}

/* Respond Below */
@mixin mediaTabletPortrait() {
  @media all and #{$tablet} {
    @content;
  }
}

/* Respond Below */
@mixin mediaMobile() {
  @media all and #{$phoneDown} {
    @content;
  }
}

@mixin iPadProLandscape() {
  @media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
    @content;
  }
}


@mixin iPadProPortrait() {
  @media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
    @content;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin gridColumn($start, $columns) {
  grid-column: ($start / span) $columns;
  -ms-grid-column: $start;
  -ms-grid-column-span: $columns;
}

@mixin gridColumn2($start, $columns) {
  grid-column: $start / calc(#{$columns} + 1);
  -ms-grid-column: $start;
  -ms-grid-column-span: $columns;
}

@mixin selection-effect($textColor) {
  color: $textColor;
  text-shadow: $rgbShiftX $rgbShiftX $rgbShiftBlur rgba(255, 0, 0, 0.5),
    $rgbShiftX $rgbShiftY $rgbShiftBlur rgba(0, 255, 0, 0.5),
    $rgbShiftY $rgbShiftX $rgbShiftBlur rgba(0, 0, 255, 0.5);
}

@mixin text-outline($outlineColor) {
  text-shadow: 0.04em 0 0 $outlineColor, -0.04em 0 0 $outlineColor,
    0 0.04em 0 $outlineColor, 0 -0.04em 0 $outlineColor,
    0.04em 0.04em $outlineColor, -0.04em -0.04em 0 $outlineColor,
    0.04em -0.04em 0 $outlineColor, -0.04em 0.04em 0 $outlineColor;
}

