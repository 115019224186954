// Resets the default styles in all browsers so they don't render things differently by default.
// The Meyer resets a great at catching fringe cases and old browser compatibility.
// It is recommended that you load "Normalize.css" after this file as it is more up to date and
// will support more modern browsers and mobile better. They work very good together.

// http://github.com/TheJaredWilcurt/meyer-sass
// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
  font: {
    size: 100%;
  }
}

// HTML5 display-role reset for older browsers

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &:before,
  &:after {
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
