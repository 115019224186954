@import '../../styles/';
.pill {
  width: 164px;
  height: 50px;
  color: black;
  background: white;
  border-radius: 100px;
  font-weight: bold;
  cursor: pointer;
  &.outline {
    background: transparent;
    border: 1px solid white;
    color: white
  }
  // @include mediaMobile() {
  //   transform: scale(0.9);
  // }
}