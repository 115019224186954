@import '../../../styles/utils.scss';

.touchNav {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.touchNavButton {
  display: flex;
  width: 4em;
  height: 4em;
  @include mediaMobile() {
    width: 2.5em;
    height: 2.5em;
  }
}
.prev {
  transform: rotate(180deg);
  position: relative;
  // top: -2px;
  width: 4em;
  height: 4em;
  @include mediaMobile() {
    width: 2.5em;
    height: 2.5em;
  }
}

.pageCount {
  display: inline-block;
  margin: 0 1.75em;
  position: relative;
  top: 0.2em;
  white-space: nowrap;
}
.slash {
  display: inline-block;
  margin: 0 0.25em;
}

.moduleDots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
}

.moduleDot {
  display: inline-block;
  padding: 0;
  width: 5px;
  height: 5px;
  transition: all 0.2s ease-out;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin: 5px;
  vertical-align: middle;
  &.light {
    background: $white;
  }
  &.dark {
    background: $mid-gray;
  }
}
.active {
  width: 9px;
  height: 9px;
  background: $white;
  &.light {
    background: $white;
  }
  &.dark {
    background: $mid-gray;
  }
}

.seen {
  background: $white;
  &.light {
    background: $white;
  }
  &.dark {
    background: $mid-gray;
  }
}
