@import '../../../styles/utils.scss';

.root {
  position: relative;
  padding: 150px 0 0 50px;
  @include mediaMobile() {
    overflow: hidden;
    padding: 0px 0 0 23px;
  }
}

.title {
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: normal;
  line-height: normal;
  @include mediaMobile() {
    max-width: 90%;
  }
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
  &.japan {
    margin-bottom: 10px !important;
  }
}
.active {
  opacity: 1;
}
.renderCasesWrapper {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
  }
  @include mediaMobile() {
    height: auto;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }
}

.blackBackground {
  background: black;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.superscript {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 300px;

  @include mediaTablet() {
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 120px;
    padding: 0 30px;
  }

  .disclaimer {
    color: #797979;
    font-size: 9px;
    line-height: 10px;
    margin-bottom: 10px;

    @include mediaTablet() {
      max-width: 450px;
      margin: 0 auto 10px auto;
    }
  }
}

