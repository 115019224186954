@import '../../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1.4);
  }
}

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 160px 50px 75px;

  @include mediaMobile() {
    padding: 100px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.currentMode {
  position: absolute;
  bottom: 13%;
  left: 0;
  text-align: center;
  width: 100%;

  span {
    font-size: 18px;
    display: block;
    padding-top: 10px;
  }
}

.title {
  font-size: 81px;
  font-weight: 400;
  @include mediaTablet() {
    font-size: 48px;
  }
}

.text {
  font-size: 12px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;

  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

.textCTA {
  margin-top: 12px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}



.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 100%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
  @include mediaMobile() {
    width: 90%;
    padding: 0 23px;
    position: absolute;
    bottom: 15%;
    margin-top: 0;
  }
  &.light {
		color: rgba(255, 255, 255, 0.5);
	}
	&.dark {
		color: rgba(37, 34, 34, 0.356);
	}
}

// leftAlign variant
.leftAlign {
  .container {
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;
  }
  .title,
  .text {
    width: 20%;
    max-width: 282px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 0.75em;
  }
  .text {
    font-size: 18px;
    flex-basis: auto;
    text-align: left;
  }
  .textCTA {
    float: none;
  }

  @include mediaTablet() {
    .title,
    .text {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaMobile() {
    .title {
      font-size: 2.22rem;
      padding-right: 0;
    }
    .text {
      font-size: 1.25rem;
    }
  }
  @include respond-to($xs-phone) {
    .title {
      font-size: 1.95rem;
    }
    .text {
      font-size: 1rem;
    }
  }
}

////// Press + Hold

.pressAndHoldContainer {
  position: absolute;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding-top: 1px; // fix Safari issue where top was appearing cut off CRWT-280

  &:hover {
    cursor: pointer;
  }
}

.pressAndHoldContainerHolder {
  position: absolute;
  left: var(--poition-width);
  top: var(--poition-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pressAndHoldText {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
  width: 300px;
  display: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;

  .outterCircle {
    height: 45px;
    width: 45px;
    background: var(--themecurr-color);
    opacity: 0.3;
    border-radius: 50%;
    pointer-events: none;
    animation: opacityAnim 1.5s infinite alternate;
  }

  .middleCircle {
    height: 60%;
    width: 60%;
    background-color: var(--themecurr-color);
    border-radius: 50%;
    z-index: 5;
    position: absolute;
    pointer-events: none;
    opacity: 0.3;
    animation: opacityAnim 1.5s infinite alternate;
    animation-delay: 0.5s;
  }

  .innerCircle {
    height: 40%;
    width: 40%;
    background-color: var(--themecurr-color);
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    pointer-events: none;
  }
}
