@import '../../styles';


.language {
	background-image: url(../../img/global/menu_bg.png);
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 0;
	text-align: center;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	justify-items: center;
	align-items: center;
	grid-template-rows: auto 1fr;
	overflow-y: auto;
  }



.header {
	font-size: 10px;
	padding-top: 60px;
	@include mediaMobile {
		padding-top: 100px;
	}
}

.item {
	font-size: 30px;
	margin-bottom: 19px;
	span {
    vertical-align: sub;
    vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;
		opacity: .25;
			transition: opacity .5s ease-out;
			-moz-transition: opacity .5s ease-out;
			-webkit-transition: opacity .5s ease-out;
			-o-transition: opacity .5s ease-out;
		&:hover {
			opacity: 1;
		}
	}
}
.selected {
	opacity: 1;
}

.languageWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	margin-top: -60px;
	@include mediaMobile {
		margin-top: 50px;
		height: calc(100% - 50px);
		justify-content: flex-start;
	}
}