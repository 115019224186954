@import '../../styles';

.language {
  background-image: url(../../img/global/menu_bg.png);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;

  @include mediaMobile {
    justify-content: flex-start;
  }
}

.header {
  font-size: 16px;
  padding-bottom: 60px;
  position: absolute;
  width: 100%;
  bottom: 100%;
  opacity: 0.8;
  @include mediaMobile {
    padding-top: 100px;
    position: initial;
    padding-bottom: 20px;
  }
}

.item {
  position: relative;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mediaTablet {
    width: 50%;
  }

  @include mediaMobile {
    width: 100%;
  }

  .productBox {
    width: calc(100% - 20px);
    background-color: #212121;
    box-shadow: 0 9px 30px rgba(0, 0, 0, 0.48);
    border-radius: 14px;
    padding: 35px 0;
    transition: transform 0.2s ease-in;

    @include mediaMobile {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 7px 5px;
      padding: 35px 20px;
    }

    .productImage {
      width: 100%;
      height: 295px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mediaTablet {
        height: auto;
      }

      @include mediaMobile {
        margin-bottom: 0;
        margin-right: 20px;
      }

      img {
        width: 100%;
        max-width: 300px;
        height: auto;
      }
    }

    .productInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include mediaMobile {
        align-items: flex-start;
        min-width: 170px;
      }
    }

    h2 {
      font-size: 32px;
      line-height: 1;
      font-weight: 300;
      text-align: center;

      @include mediaTablet {
        font-size: 24px;
      }

      @include mediaMobile {
        text-align: left;
        font-size: 22px;
      }
    }

    .progress {
      color: #bababa;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1;
      text-align: left;
      font-weight: 300;
      margin: 10px 0 20px 0;

      @include mediaMobile {
        font-size: 14px;
      }
    }

    .completed {
      color: #3ef298;
    }

    .cta {
      display: inline-block;
      width: 144px;
      background-color: transparent;
      color: #fff;
      text-align: center;
      padding: 15px 0 12px 0;
      font-size: 12px;
      font-weight: bold;
      border-radius: 30px;
      border: 1px solid #fff;
      text-transform: uppercase;
      transition: background-color 0.5s linear, color 0.5s ease-out;

      &:active {
        background-color: transparent;
        color: #fff;
      }
    }
  }

  &:hover {
    .productBox {
      background-color: #4e4e4e;
      transform: scale(1.02);
    }

    .cta {
      background-color: #fff;
      color: #000;
    }
  }
}
.selected {
  opacity: 1;
}

.languageWrapper {
  position: relative;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include mediaMobile {
      flex-direction: column;
      width: 100%;
    }
  }
}
