@import '../../styles/utils.scss';

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
//     -webkit-transition-delay: 9999s;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

.signinHolder {
  background-image: url(../../img/global/menu-bg.jpg);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  // cursor: pointer;
  @include mediaMobile {
    left: 0;
    padding: 50px 0 100px;
  }
}

.exitDrawerButton {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 50px;
  right: 47px;
  cursor: pointer;
  z-index: 100;
}

.forgotPasswordWrapper {
  margin-top: 2em;
  height: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.2);
  text-decoration: underline;
  cursor: pointer;
  width: 392px;
  text-align: left;
  text-transform: uppercase;
  @include mediaMobile() {
    width: 300px;
  }
}
.forgetDrawer {
  width: 700px;
  height: 100%;
  right: 0%;
  position: absolute;
  @include mediaTablet() {
    width: 100%;
  }
}
.forgetDrawerHolder {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  transform: translateX(100%);
}
.passwordLogIndWrapper,
.emailWrapper {
  width: 392px;
  position: relative;
  display: flex;
  input {
    width: 100%;
    padding-right: 30px;
  }
  .submitPassword,
  .submitLogin {
    position: absolute;
    right: 0%;
    height: 23px;
    width: 23px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .inactive {
    opacity: 0.3;
  }
  .ruleValid {
    color: rgb(255, 255, 255) !important;
    opacity: 1;
  }
  .checkMark {
    margin-right: 5px;
  }

  .submitPassword {
    position: relative;
    right: auto;
    border-radius: 50%;
    margin-left: 14px;
  }
  @include mediaMobile() {
    width: 300px;
  }
}

.showHidePasswordWrapper {
  display: flex;
  position: absolute;
  right: 0%;
  align-items: center;
}

.passwordRules {
  text-align: left;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.2);
  line-height: 16px;
  margin-top: 2em;
  height: 0;
}

.showHidePasswordText {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.2);
  text-decoration: underline;
  cursor: pointer;
}

.passwordLogIndWrapper {
  flex-direction: column;
}

.passwordInputWrapper {
  position: relative;
  display: flex;
}

.chevronImage {
  height: 9px;
  opacity: 0.25;
  margin-left: 2px;
}
.submitLogin {
  display: inline-block;
  border-radius: 50%;
}

.submitLogin:hover .left,
.submit:hover .top,
.submit:hover .bottom,
.submit:hover .right {
  border: 0.2em solid #ffffff;
}

.submitLogin:hover .left:after,
.submit:hover .top:after,
.submit:hover .bottom:after,
.submit:hover .right:after {
  border-top: 0.2em solid #ffffff;
  border-right: 0.2em solid #ffffff;
}

.right {
  display: inline-block;
  width: 23px;
  height: 23px;
  border: 0.2em solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.userInfoWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include mediaMobile() {
    position: relative;
    left: 0;
    top: 0;
    margin-top: 150px;
    height: 100%;
    overflow: auto;
    transform: none;
    width: 90%;
    padding: 0 15px;
  }
}

.infoForm {
  width: 392px;
  display: grid;
  grid-gap: 60px;
  @include mediaMobile {
    width: 100%;
  }
}
.nameWrapper {
  display: flex;
  justify-content: space-between;
  input {
    width: 47%;
  }
}

.userInfoTitle {
  font-size: 32px;
  margin-bottom: 66px;
  font-weight: normal;
  text-align: left;
}

select,
input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: white;
  padding-bottom: 16px;
  font-size: 16px;

  @include mediaMobile {
    border-width: 0 0 1px;
  }
  &:hover {
    option {
      color: black !important;
    }
  }
}

.email-input {
  transition: border-color 0.25s;
}

.inactive {
  opacity: 0.3;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 32%;
  align-items: flex-start;
  label {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 13px;
    text-transform: uppercase;
  }
  @include mediaMobile {
    width: 100%;
    label {
      font-size: 14px;
    }
  }
}

select option {
  color: black;
  &:checked,
  &:hover {
    color: black;
  }
}

.inputWrapperHalf {
  width: 49%;
  @include mediaMobile {
    width: 100%;
  }
}

.inputWrapperFull {
  width: 100%;
}

input:focus {
  border-color: rgba(255, 255, 255, 0.8);
  &.error {
    border-color: #ef2642;
  }
}

.inputFullWidth {
  width: 100%;
}

button {
  border: 0;
  background: transparent;
}

.submit {
  display: inline-block;
  border-radius: 100px;
  width: 100%;
  height: 50px;
  background: white;
  color: black;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  @include mediaMobile() {
    width: 88%;
    margin: 0 auto;
  }
}

.hide {
  display: none;
}

// Settings !

.backButton {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 60px;
  cursor: pointer;
  img {
    transform: rotate(180deg);
    margin-right: 15px;
  }
  @include mediaTablet {
    margin-bottom: 40px;
  }
}

.settingsWrapper {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  width: 65%;
  @include mediaTablet {
    position: relative;
    width: 90%;
    top: auto;
    transform: translate(0, 0);
    left: auto;
    margin: 0 auto;
    margin-top: 130px;
  }
  @include mediaMobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    overflow-y: scroll;
    padding-bottom: 50px;
    position: relative;
    max-height: 100%;
    padding: 0 15px;
  }
}

.settingsForm {
  width: 100%;
  display: grid;
  grid-gap: 41px;
  @include mediaMobile {
    grid-gap: 0px;
  }
}

.settingsNameWrapper {
  display: flex;
  justify-content: space-between;
  input {
    width: 100%;
  }
  @include mediaMobile {
    flex-direction: column;
    input {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.businessDropdownWrapper {
  display: flex;
  justify-content: space-between;
  select,
  input {
    width: 100%;
  }
  @include mediaMobile {
    display: flex;
    flex-direction: column;
    select,
    input {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.locationWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 41px 15px;
  select,
  input {
    width: 100%;
  }
  @include mediaMobile {
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.passwordWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  button {
    position: absolute;
    right: 0%;
    text-transform: uppercase;
    top: 25px;
    font-size: 12px;
  }
}

.settingsSubmit {
  display: inline-block;
  border-radius: 100px;
  width: 100%;
  height: 50px;
  background: white;
  color: black;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  max-width: 392px;
  margin: 0 auto;
  margin-top: 40px;
  @include mediaTablet {
    margin-top: 50px;
  }

  @include mediaMobile() {
    width: 88%;
  }
}

.errorMessage {
  font-size: 12px;
  color: #ef2642;
  text-align: left;
  position: absolute;
  top: 42px;
  &.settingsErrorMsg {
    top: 73px;
  }
}
