@import '../../../styles/utils.scss';

.root {
  @include mediaTablet() {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 160px 50px 75px;
  height: calc(var(--vh, 1vh) * 100);
  @include mediaTablet() {
    padding: 110px 50px 0px;
  }
  @include mediaMobile() {
    height: calc(var(--vh, 1vh) * 100);
    padding: 175px 50px 0px;
  }
  @include respond-to($short-desktop) {
    padding-top: 100px;
  }
}

.title {
  font-size: 36px;
  font-weight: normal;
  @include mediaMobile() {
    font-size: 2.1rem;
    margin-bottom: 0.1em;
  }
}

.text {
  font-size: 18px;
  font-weight: normal;
  width: 246px;
  margin-top: 30px;
  line-height: 22px;
  @include mediaTablet() {
    font-size: 16px;
    margin-top: 20px;
  }
  @include mediaMobile() {
    margin-top: 0px;
    width: 100%;
    font-size: 1rem;
    line-height: normal;
  }
  @include respond-to($short-desktop) {
    margin-top: 10px;
  }
}

.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 20%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
  @include mediaMobile() {
    width: 100%;
    padding: 0 23px;
    position: absolute;
    bottom: 0px;
    transform: translateY(-50%);
  }
  @include respond-to($short-desktop) {
    margin-top: 20px;
  }
}

.stepWrapper {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  margin-top: 52px;
  margin: 0 auto;

  @include mediaMobile() {
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 100%;
    margin: 0 0 60px;
    padding-left: 23px;
    grid-template-columns: repeat(6, 140px);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.colorPickerStepWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 96px;
  @include mediaMobile() {
    bottom: 0px;
  }
}

.note {
  color: white;
  opacity: 0.5;
  width: 246px;
  font-size: 9px;
  margin-top: 30px;
}

.stepCTA {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
  @include mediaTablet() {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.stepContainer {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 30px;
  z-index: 20;
  @include mediaTablet() {
    bottom: 0%;
  }
  @include iPadProPortrait() {
    bottom: 0%;
  }
  @include iPadProLandscape() {
    bottom: -5%;
  }
  @include respond-to($short-desktop) {
    bottom: -9%;
  }
}

.stepContainerColorPicker {
  bottom: 15%;
}

// Mobile

.mobileContainer {
  padding: 90px 23px 0;
}
.bottomContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  bottom: 13%;
  transform: translateX(-50%);
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowButtonWrapper {
  cursor: pointer;
}

.counter {
  margin: 0 24px;
  font-size: 14px;
}

.colorPickerBottomContainer {
  top: 67%;
}

.hideCursor {
  cursor: none;
}
