/* Colors */
$black: #222222;
$white: #ffffff;
$coral: #fa5959;
$gray: #999999;
$yellow: #fff500;
$dark-gray: #313A3A;
$mid-gray: #727272;

/* Fonts */
$andale: "Andale Mono", serif;
$mono: "NeuzeitGro-Reg", serif;

$regular: 400;
$medium: 500;

/* Media Queries */
$xs-phone: "(max-width: 321px)";
$phone: "(min-width: 480px)";
$phoneDown: "(max-width: 480px)";
$tablet: "(min-width: 768px) and (orientation: portrait)";
$tabletDown: "(max-width: 768px)";
$tablet-landscape: "(min-width: 768px) and (orientation: landscape)";
$desktop: "(min-width: 1025px)";
$average-desktop: "(min-width: 1240px)";
$largest-screens: "(min-width: 2000px)";
$short-desktop: "(max-height: 800px)";
$mobile: "(max-width: 1024px)";

/* Timing */
$speed: 333ms;
$speed-metal: 666ms;

/* Easing */
$ease-in: cubic-bezier(0.55, 0.055, 0.666, 0.19);
$ease-out: cubic-bezier(0.237, 0.666, 0.333, 1);
$evil-ease: cubic-bezier(0.666, 0, 0.237, 1);

/* Sizing */
$max_width: 1920px;
$min_width: 320px;

/* ::SELECTION */
$rgbShiftX: 0.03em;
$rgbShiftY: -0.03em;
$rgbShiftBlur: 0;
