// font family definition here

h1 {
}

// etc

p {
  font-size: 16px;

  @include respond-to($desktop) {
    font-size: 24px;
  }
}

a {
  text-decoration: none;
}
