@import '../../../../styles/utils.scss';

.item {
  filter: brightness(100%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: filter 0.25s;
  height: 380px;
  width: 330px;
  &:hover {
    filter: brightness(110%);
  }
  @include mediaMobile() {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgba(255,255,255,0.2);
  }
}

.image {
  background-size: contain;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
  border-radius: 12px;
  height: 208px;
 width: 300px;

  @include mediaMobile() {
    height: 89px;
    width: 129px;
  }
}

.description {
 font-size: 18px; 
 width: 300px;
 margin-top: 30px;
 line-height: 22px;
 @include mediaMobile {
   font-size: 16px;
   line-height: 20px;
   max-width: 223px;
   margin-left: 18px;
   margin-top: 0;
 }
 @include respond-to($xs-phone) {
   font-size: 1.2rem;
 }
}

