@import '../../styles';

.siteholder {

	&.japan {
		font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	
	  }

	&.light {
		color: $white;
	}
	&.dark {
		color: $black;
	}
	&.korean {
		word-break: keep-all;
	}
}


