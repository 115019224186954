@import '../../styles/utils.scss';

.root {
  display: inline-flex;
  align-items: center;
  margin-top: 34px;
  cursor: pointer;
  width: 100px;
  z-index: 20;

  @include mediaTablet() {
    margin-top: 25px;
  }
  @include mediaMobile() {
    position: fixed;
    bottom: 25px;
    left: 20px;
  }
  @include respond-to($short-desktop) {
    margin-top: 15px;
  }
}
.icon {
  height: 16px;
  width: 13px;
}

.label {
  font-size: 10px;
  margin-left: 8.3px;
  text-transform: uppercase;
  line-height: 16px; // height of icon
  @include mediaMobile() {
    display: none;
  }
}
