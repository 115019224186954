@import '../../styles';

.root {
  overflow: hidden;
}

.hide {
  display: none;
}

.audioControl {
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 1000;
  @include mediaMobile() {
    right: 20px;
  }
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
}

.mainVideoContainer {
  width: 100vmax;
  height: 100vmax;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
}

.mainVideoCanvas {
  width: 100%;
  height: 100%;
}
