@import '../../../styles/utils.scss';

@include keyframes(opacityAnim) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include keyframes(opacityAnimInvert) {
  0% {
    opacity: 0;
    transform: scaleX(-1);
  }
  50% {
    opacity: 1;
    transform: scaleX(-1);
  }
  100% {
    opacity: 0;
    transform: scaleX(-1);
  }
}

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 160px 50px 75px;

  @media (any-hover: none) {
    padding-bottom: 95px;
  }

  @include mediaMobile() {
    padding: 90px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }
}

@mixin supMain {
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
}

.title {
  font-size: 81px;
  font-weight: 400;
  @include supMain();
  @include mediaTablet() {
    font-size: 48px;
  }
}

.text {
  font-size: 12px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;
  @include supMain();
  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
.left {
  text-align: left !important;
}

.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 20%;
  margin-top: 25px;
  opacity: 0.5;

  @include mediaMobile() {
    width: 90%;
    padding: 0 10px;
    position: absolute;
    bottom: 12%;
    margin-top: 0;
  }
}

.bgImageWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;

  .bgContent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .bgIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: 0.3s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    &.active:not(.invert) {
      animation: opacityAnim 1s ease-out forwards;
    }
    &.active.invert {
      animation: opacityAnimInvert 1s ease-out forwards;
    }

    &.invert {
      transform: scaleX(-1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.stepWrapper {
  padding: 20px 0;
}

.stepContainer {
  position: absolute;
  bottom: 13%;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 20;
}

.stepCTA {
  margin: 15px 0;
  font-size: 16px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;
  @include supMain();

  @include mediaTablet() {
    margin: 7px 0;
  }

  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

// leftAlign variant
.leftAlign {
  .container {
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;
  }
  .title,
  .text,
  .stepCTA {
    width: 27%;
    max-width: 282px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 0.75em;
  }
  .text,
  .stepCTA {
    flex-basis: auto;
    text-align: left;
  }
  .text {
    font-size: 18px;
  }

  @include mediaTablet() {
    .title,
    .text,
    .stepCTA {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaMobile() {
    .title {
      font-size: 2.1rem;
      padding-right: 0;
      margin-bottom: 0.1em;
    }
    .text {
      font-size: 1rem;
    }
    .stepCTA {
      font-size: .9rem;
      max-width: unset;
      width: 100%;
      align-self: flex-start;
    }
  }
  @include respond-to($xs-phone) {
    .title {
      font-size: 1.7rem;
    }
    .text {
      font-size: 0.9rem;
    }
    .stepCTA {
      font-size: 0.85rem;
    }
  }
}
