@import '../../../styles/utils.scss';

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 100%;
    overflow: hidden;
    @include mediaMobile() { 
        justify-content: initial;
        // padding: 150px 0px 0;
    }
}


.title {
    margin-left: 50px;
    font-size: 40px;
    font-weight: 400;
    @include mediaMobile() {
        font-size: 2.3rem;
        margin-left: 0px;
        padding: 5em 0 1em;
    }
    @include respond-to($xs-phone) {
        font-size: 1.9rem;
    }
    &.light {
        color: white;;
    }
    &.dark {
        color: black;
    }
}

.underline {
    margin-left: 50px;
    height: 1px;
    background: white;
    width: 150px;
    margin-top: 40px;
    position: relative;
    &.light {
        background: white;;
      }
      &.dark {
        background: black;
      }
}

.gradientContainer {
    position: relative;
    width: 100%;
    
    .gradient {
        min-width: 500px;
        width: 600px;
        top: 0;
        height: 100vh;
        position: absolute;
        z-index: 1;
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.25s;
    }

    .left {
        left: 0%;
    }

    .right {
        right: 0%;
    }

    .hide {
        opacity: 0;
    }
}

.mobileGradient {
    position: absolute;
    right: 0%;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    height: 25%;
    bottom: 0%;
}

.gradientTop {
    position: absolute;
    z-index: 1;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 20%;
}

.mobileList {
    display: flex;
    flex-direction: column;
    // height: 100%;
    overflow: scroll;
    padding: 0 20px;
    padding-bottom: 100px;
    // margin-top: 41px;
}