@import '../../../styles/utils.scss';

.bgImg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 160px 50px 75px;
  position: absolute;
  top: 0;
  width: 100%;
  @media (any-hover: none) {
    padding-bottom: 95px;
  }
  @include mediaTablet() {
    padding-top: 115px;
  }
  @include mediaMobile() {
    padding: 90px 20px;
    flex-direction: column;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 100);
  }
}

.mainWrapper {
  position: absolute;
  left: 0;
  width: 100vw;
  bottom: 50%;
  transform: translate(0, 42%);
  @include mediaTablet() {
    transform: translate(0, 50%);
  }
  @include respond-to('(max-height: 800px) and (max-width: 768px)') {
    transform: translate(0, 53%);
  }
  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.productWrapper {
  max-width: 55vh;
  @include mediaMobile() {
    max-width: 43vh;
  }
  @include respond-to('(max-height: 700px)') {
    max-width: 45vh;
  }
  @include respond-to('(max-height: 800px) and (max-width: 768px)') {
    max-width: 40vh;
  }
  .productCanvas {
    width: 100%;
    height: 100%;
  }
}

.modesWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 14%;
  font-size: 15px;
  width: 23em;
  margin-top: 1em;
  z-index: 30;
  .textGroup {
    display: flex;
    justify-content: space-between;
  }
  .modeText {
    width: 6em;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }
  @include mediaTablet() {
    font-size: 13px;
    width: 20em;
  }
}

.iconGroup {
  width: 85%;
  height: 3em;
  border-radius: 5em;
  margin: 0 auto 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2em;
  .iconCircle {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      z-index: 50;
    }
    .circleBg {
      border-radius: 50%;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      &.light {
        background-color: $dark-gray;
      }
      &.dark {
        background-color: $white;
      }
    }
  }
  &.light {
    background-color: $white;
  }
  &.dark {
    background-color: $dark-gray;
  }
}

.soundtrack {
  display: flex;
  position: absolute;
  align-items: center;
  right: 50px;
  div {
    text-align: right;
    margin-right: 15px;
  }
  p,
  b {
    font-size: 15px;
    line-height: 1.2;
  }
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 15%;
  }
  @include mediaTablet() {
    display: none;
  }
}

@mixin supMain {
  sup {
    vertical-align: super;
    font-size: 0.6em;
  }
}

.title {
  font-size: 81px;
  font-weight: 400;
  @include supMain();
  @include mediaTablet() {
    font-size: 48px;
  }
}

.text {
  font-size: 12px;
  flex-basis: 25%;
  line-height: 1.333;
  text-align: right;
  @include supMain();
  @include mediaMobile() {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
.left {
  text-align: left !important;
}
.textCTA {
  margin-top: 12px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

.disclaimer {
  font-size: 9px;
  line-height: 1.333;
  width: 20%;
  margin-top: 25px;
  color: rgba(255, 255, 255, 0.5);
  @include mediaMobile() {
    width: 90%;
    padding: 0 23px;
    position: absolute;
    bottom: 15%;
    margin-top: 0;
  }
}

// leftAlign variant
.leftAlign {
  .container {
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;
  }
  .title,
  .text {
    width: 20%;
    max-width: 282px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 0.75em;
  }
  .text {
    font-size: 18px;
    flex-basis: auto;
    text-align: left;
  }
  .textCTA {
    float: none;
  }

  @include mediaTablet() {
    .title,
    .text {
      width: 100%;
      max-width: none;
    }
    .title {
      padding-right: 2em;
      margin-bottom: 0.25em;
    }
  }

  @include mediaMobile() {
    .title {
      font-size: 2.1rem;
      padding-right: 0;
      margin-bottom: 0.1em;
    }
    .text {
      font-size: 1rem;
    }
  }
  @include respond-to($xs-phone) {
    .title {
      font-size: 1.7rem;
    }
    .text {
      font-size: 0.9rem;
    }
  }
}
