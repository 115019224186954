@import "../../../../styles/utils.scss";

.wrapper {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  z-index: 20;
  position: relative;
  @include mediaTablet() {
    flex-direction: column;
    align-items: flex-start;
  }
  @include iPadProPortrait() {
    flex-direction: column;
    align-items: flex-start;
  }
}

.opacityWrapper {
  opacity: 1;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    .title {
      color: #dddbdb;
    }
  }
  &.use-case-disabled {
    opacity: 0.2;
    transition: opacity 0.3s;
  }
  @include mediaMobile() {
    align-items: initial;
    margin-bottom: 10px;
    &.japan {
      align-items: center !important;
    }
  }
}
.playButton {
  padding-top: 5rem;
  background: red;
}

@mixin useCaseTitleMedia {
  @media (max-width: 1024px) {
    font-size: 70px;
  }
  @include iPadProPortrait() {
    white-space: nowrap;
  }
  @media (max-height: 750px )and (max-width: 1240px) {
    font-size: 80px;
  }
  @include mediaTablet() {
    font-size: 60px;
    margin-bottom: 15px;
    white-space: nowrap;
  }
  @include mediaMobile() {
    font-size: 31px;
    margin-left: 14px;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  @include respond-to($xs-phone) {
    font-size: 26px;
  }
}
.useCaseTitle {
  font-size: 100px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-left: 24px;
  transition: color 0.2s;
  width: min-content;
  white-space: nowrap;
  span.cutoff {
    vertical-align: sub;
    vertical-align: -webkit-baseline-middle;
    &.japan {
      vertical-align: middle !important;
    }
    vertical-align: -moz-middle-with-baseline;
  }
  @include useCaseTitleMedia()
}

.useCaseTitleCutoff {
  margin-bottom: 0;
  font-size: 110px;
  @include useCaseTitleMedia()
}

.description {
  font-size: 18px;
  line-height: 22px;
  max-width: 280px;
  margin-left: 24px;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  // transition-delay: 0.1s;
  transform: translateX(-20px);
  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
  @include mediaTablet() {
    font-size: 18px;
    line-height: normal;
    max-width: none;
    margin-left: 62px;
  }
  @include iPadProPortrait() {
    max-width: none;
    margin-left: 62px;
  }
  @include mediaMobile() {
    font-size: 13px;
    line-height: normal;
    margin-left: 42px;
    max-width: 80%;
    transition-delay: 0s;
    transition: transform 0s, opacity 0s;

    &.active {
      position: relative;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
}

.textWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.animWrapper {
  transition: height 0.25s;
}
