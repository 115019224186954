@import "../../../styles/utils.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  @include mediaMobile() {
    height: 100%;
  }
}
.container {
  max-width: 780px;
  width: 100%;
  padding: 0 50px;
  text-align: center;
  @include mediaMobile() {
    max-width: 100vw;
    padding: 0 10px;
  }
  overflow: hidden;

}

.eyebrow {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 1em;
  letter-spacing: 0.05em;
  line-height: normal;
}
.title {
  font-size: 80px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: -0.05em;

  @include mediaMobile() {
    font-size: 40px;
  }
  span {
    vertical-align: text-top;
  }
  em {
    font-weight: 700;
    font-style: normal;
  }
}
.title3 {
  @include mediaMobile() {
    font-size: 40px;
  }
  @include respond-to($xs-phone) {
    font-size: 35px;
  }
}
.title5 {
  @include mediaMobile() {
    font-size: 65px;
  }
  @include respond-to($xs-phone) {
    font-size: 58px;
  }
}

.subTitle {
  font-size: 24px;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  line-height: normal;
  @include mediaMobile() {
    padding: 0 2rem;
    font-size: 18px;
    bottom: 50px;
  }
}
