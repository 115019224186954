@import '../../styles/utils.scss';

#menu {
  background-image: url(../../img/global/menu-bg.jpg);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  z-index: 100;
  &.open {
    pointer-events: initial;
  }
  @include mediaMobile() {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.gradient {
  display: none;
  position: absolute;
  width: 100%;
  height: 130px;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(53,64,64,0) 0%, rgba(53,64,64,0.5) 25%, rgba(53,64,64,1) 100%);
  z-index: 3;

  @include mediaMobile() {
    display: block;
  }
}

.menuGrid {
  display: flex;
  flex-direction: row;

  @include mediaBigTablet() {
    flex-direction: column;
  }

  @include mediaMobile() {
    overflow-y: auto;
  }
}

.menucol {
  position: relative;
  float: left;
  width: 50%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  display: flex;
  align-items: center;
  &.chapters {
    height: calc(var(--vh, 1vh) * 100);
    background-color: #354040;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  @include mediaBigTablet() {
    float: none;
    width: 100%;
    padding-top: 0px;
    &.chapters {
      height: 100%;
      padding: 0px 23px;
      padding-top: 41px;
      overflow-y: scroll;
      position: relative;
    }
  }

  @include mediaMobile() {
    overflow-y: initial;

    &.chapters {
      height: auto;
      overflow-y: initial;
    }
  }
}

.hello-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaBigTablet {
    justify-content: center;
    align-items: flex-start;
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0px, 0px);
    padding-bottom: 39px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 90%;
    margin: 0 auto;
    margin-top: 120px;
  }
}

#salutation {
  font-size: 30px;
  margin-bottom: 19px;
  line-height: normal;
  @include mediaBigTablet {
    display: none;
  }
}

#percent {
  font-size: 30px;
  width: 206px;
  line-height: 34px;
  margin-bottom: 40px;
  @include mediaBigTablet {
    display: none;
  }
}

#salutation-mobile {
  display: none;
  @include mediaBigTablet {
    font-size: 30px;
    color: white;
    text-align: left;
    display: block;
  }
}

.button-wrapper {
  display: grid;
  grid-gap: 17px;
  @include mediaBigTablet {
    display: flex;
    grid-gap: 0px;
    justify-content: flex-start;
    margin-top: 39px;
    width: 100%;
    &.kiosk {
      justify-content: center;
    }
    button {
      margin-right: 14px;
    }
  }
}

.chapter-wrapper {
  display: flex;
  height: 50%;
  justify-content: flex-start;
  flex-direction: column;

  li {
    list-style: none;
  }
  @include mediaBigTablet {
    height: 900px;
    max-height: 100%;
    overflow: scroll;
    width: 100%;
    &.scroll {
      height: 1400px;
    }

    li:not(.moduleTitle) {
      margin-bottom: 25px;
    }
  }

  @include mediaMobile() {
    height: 100%;

    &.scroll {
      height: auto;
      overflow: initial;
    }
  }
}
.chapterTitleWrapper {
  cursor: pointer;
  padding-bottom: 10px;
  align-items: center;
  opacity: 1;
  transition: opacity 0.25s;
  margin-left: 60px;
  display: inline-flex;
  white-space: nowrap;
  &.cutoff {
    padding-bottom: 0;
  }
  &.hide {
    transition: opacity 0s;
    opacity: 0.25;
  }
  &.hide.completed {
    opacity: 1;
  }
  @include mediaBigTablet() {
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

.chapter-list-wrapper {
  position: relative;
  opacity: 0;
  margin-left: 106px;
  margin-bottom: 0px;
  transition: height 0.25s ease-out;
  overflow: hidden;
  &.show {
    overflow: unset;
    display: block;
    opacity: 1;
    margin-bottom: 25px;
  }
  @include mediaBigTablet {
    margin-left: 30px;
  }
  @include mediaMobile {
    margin-left: 23px;
  }
}

a {
  text-decoration: none;
  color: white;
}

button {
  color: white;
}

.chapterTitle-chapter {
  font-size: 16px;
  margin-right: 46px;

  @include mediaBigTablet() {
    margin-right: 23px;
  }
  @include mediaMobile() {
    font-size: 12px;
    margin-right: 23px;
  }
}

@mixin chapterTitleMedia {
  @media (max-width: 1200px) {
    font-size: 5.5vw;
  }
  @include mediaMobile() {
    font-size: 30px;
  }
}

.chapterTitle {
  font-size: 54px;
  &.cutoff {
    font-size: 50px;
    line-height: normal;
    @include chapterTitleMedia();
  }
  @include chapterTitleMedia();
}

.moduleTitle {
  margin-left: 30px;
  font-size: 13px;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 75%;


  @include mediaBigTablet() {
    margin-left: 27px;
    max-width: 90%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 0px solid;
    padding: 20px 0 0;
    margin-top: 20px;
    font-size: 13px;
  }
}
.moduleTitle__link {
  display: flex;
  justify-content: space-between;
  .moduleTitle__text {
    max-width: 90%;
    line-height: 20px;

  }
}
.moduleTitle__text {
  padding-top: 5px;
}
.module-title__arrow {
}
.moduleTitle__inactive {
  opacity: 0.3;
}
.menu__restart-experience {
  color: white;
  font-size: 12px;
  margin-left: 60px;
  margin-top: 14px;
  cursor: pointer;
  padding: 30px 0 0;
  position: relative;
  text-transform: capitalize;

  &:before {
    content: '';
    width: 35px;
    height: 2px;
    background: #ffffff36;
    position: absolute;
    top: 0;
    left: 0;
  }

  ul {
    li {
      margin-top: 20px;
      font-size: 16px;
    }
  }

  @include mediaBigTablet() {
    margin-left: 0px;
    margin-bottom: 30px;
  }
}

.menu__contact-us {
  position: absolute;
  left: 50px;
  bottom: 30px;
  color: white;
  font-size: 12px;
  opacity: 0.5;
  a {
    text-decoration: none;
  }

  @include mediaBigTablet() {
    display: none;
  }
  z-index: 99;
  p {
    font-size: 12px;
  }
}

.menu__contact-us--mobile {
  color: white;
  font-size: 12px;
  opacity: 0.5;
  display: none;
  margin-top: 30px;
  padding: 0 0 25px;
  a {
    text-decoration: none;
  }
  @include mediaBigTablet() {
    display: block;
  }
}

.menu__middle-line {
  height: 100%;
  width: 1px;
  background: white;
  opacity: 0.4;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  @include mediaBigTablet() {
    display: none;
  }
}
