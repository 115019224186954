@import '../../styles';

.component {
  position: fixed;
  padding: 10px;
  width: 45px;
  height: 35px;
  right: 2vw;
  top: 30px;
  // transform: translate3d(0, -100px, 0);
  cursor: pointer;
  z-index: 999;

  &.mounted {
    transition: transform 700ms $evil-ease;
    transform: translate3d(0, 0, 0);
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .line {
    position: absolute;
    width: 16px;
    height: 2px;
    border-radius: 20%;
    overflow: hidden;
    opacity: 1;
    &.dark {
      &:before {
        content: '';
        position: absolute;
        right: 0%;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 1;
      }

      &:nth-child(1) {
        &.closed {
          animation-name: SetNavActiveLine1-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine1;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.2s;

          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.2s;
          }
        }
      }
      &:nth-child(2) {
        &.closed {
          animation-name: SetNavActiveLine2-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.1s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.1s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine2;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.1s;

          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.1s;
          }
        }
      }
      &:nth-child(3) {
        width: 8px;
        &.closed {
          animation-name: SetNavActiveLine3-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.2s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.2s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine3;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0s;
          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0s;
          }
        }
      }
    }
    &.light {
      &:before {
        content: '';
        position: absolute;
        right: 0%;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 1;
      }

      &:nth-child(1) {
        &.closed {
          animation-name: SetNavActiveLine1-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine1;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.2s;

          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.2s;
          }
        }
      }
      &:nth-child(2) {
        &.closed {
          animation-name: SetNavActiveLine2-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.1s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.1s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine2;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.1s;

          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.1s;
          }
        }
      }
      &:nth-child(3) {
        width: 8px;
        &.closed {
          animation-name: SetNavActiveLine3-rev;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0.2s;

          &:before {
            animation-name: SetNavActiveLineBefore-rev;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0.2s;
          }
        }

        &.open {
          animation-name: SetNavActiveLine3;
          animation-duration: $speed-metal;
          animation-timing-function: $evil-ease;
          animation-fill-mode: both;
          animation-delay: 0s;
          &:before {
            animation-name: SetNavActiveLineBefore;
            animation-duration: $speed-metal;
            animation-timing-function: $evil-ease;
            animation-fill-mode: both;
            animation-delay: 0s;
          }
        }
      }
    }
  }
}

// Line 1
@include keyframes(SetNavActiveLine1) {
  0% {
    top: 0%;
    transform: translateY(0%) rotate(0deg);
  }
  50% {
    top: 0%;
    transform: translateY(0%) rotate(0deg);
  }
  60% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  100% {
    top: 50%;
    width: 16px;
    transform: translateY(-50%) rotate(45deg);
  }
}

@include keyframes(SetNavActiveLine1-rev) {
  0% {
    width: 16px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  60% {
    top: 0%;
    transform: translateY(0%) rotate(0deg);
  }
  100% {
    top: 0%;
    transform: translateY(0%) rotate(0deg);
  }
}

// Line 2
@include keyframes(SetNavActiveLine2) {
  0% {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  50% {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  60% {
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include keyframes(SetNavActiveLine2-rev) {
  0% {
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  50% {
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  60% {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Line 3
@include keyframes(SetNavActiveLine3) {
  0% {
    top: 90%;
    transform: translateY(0%) rotate(0deg);
  }
  50% {
    top: 90%;
    transform: translateY(0%) rotate(0deg);
  }
  60% {
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    width: 16px;
  }
}

@include keyframes(SetNavActiveLine3-rev) {
  0% {
    width: 16px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
  }
  60% {
    top: 90%;
    transform: translateY(0%) rotate(0deg);
  }
  100% {
    top: 90%;
    transform: translateY(0%) rotate(0deg);
  }
}

// Before
@include keyframes(SetNavActiveLineBefore) {
  0% {
    right: 0%;
  }
  50% {
    right: -100%;
  }
  60% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}
@include keyframes(SetNavActiveLineBefore-rev) {
  0% {
    right: 0%;
  }
  50% {
    right: -100%;
  }
  60% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}
