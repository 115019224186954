@import "../../../../styles/utils.scss";

.interactive-step {
  display: grid;
  justify-items: center;
  grid-auto-rows: 1fr 100px;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 20;
  position: relative;

  &.block {
    opacity: 0.4;
  }
  @media (any-hover: none) {
    grid-auto-rows: 1fr 120px;
  }
  @include mediaMobile() {
    grid-auto-rows: 1fr 50px;
    &:nth-of-type(6) {
      padding-right: 23px;
    }
  }
}

.icon-wrapper {
  height: 76px;
  width: 76px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.51);
  transition: border-width 0.25s, background-color 0.25s;

  &.fill {
    background: white;
    border: 0px solid;
  }
  svg {
    margin-top: 5px;
  }
  @include mediaTablet() {
    height: 60px;
    width: 60px;
    svg {
      width: 30px;
    }
  }

  @include mediaMobile() {
    height: 76px;
    width: 76px;
    svg {
      width: auto;
    }
  }
}

.text {
  font-size: 12px;
  line-height: 18px;
  max-width: 140px;
  margin-top: 18px;
  @include mediaTablet() {
    font-size: 11px;
    margin-top: 10px;
  }
  @include mediaMobile() {
    font-size: 14px;
    margin-top: 18px;
  }
}

.color-picker-interactive-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 48px;
  cursor: pointer;
  z-index: 20;

  &:nth-of-type(1) {
    margin-left: 0;
  }
}

.color-picker-color {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid white;

  &.active {
    border-width: 6px;
  }
}

.color-text {
  opacity: 1;
  transition: opacity 0.25s;
  &.fade {
    opacity: 0.5;
  }
  @include mediaMobile() {
    max-width: 32px;
    text-align: center;
    height: 0px;
  }
}
